import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IBill, IBillByIdResponse, ICrudBillFilterParams, IValuesBill } from "../../api/Interfaces/managment/ICrudBill";
import { IPostResult } from "../../api/Interfaces/managment/ICrudDescount";
import errorHandler from "../errorHandler";
import { selectListResponse } from "../interfaces/utils/IUtilDeclaration";

const GetBills = async (params: ICrudBillFilterParams): Promise<IApiResponse<IBill[]>> => {
    try{
        const response = await api.GetBills(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetBillsSelect = async (socialWorkId: number | null): Promise<IApiResponse<selectListResponse[]>> => {
    try{
        const response = await api.GetBillsSelect(socialWorkId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateBill = async (params: IValuesBill): Promise<IApiResponse<IPostResult>> => {
    try{
        const response = await api.CreateBill(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const EditBill = async (billId: number, params: IValuesBill): Promise<IApiResponse<void>> => {
    try{
        const response = await api.EditBill(billId, params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteBill = async (id: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteBill(id);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetBillById = async (id: number, conDebitoTemporal: boolean = false): Promise<IApiResponse<IBillByIdResponse>> => {
    try{
        const response = await api.GetBillById(id, conDebitoTemporal);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}
export { CreateBill, DeleteBill, GetBills, GetBillsSelect, GetBillById, EditBill};
