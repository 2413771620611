import { Typography } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import React, { useEffect, useState } from "react";
import SideBarItem from "../sideBar/SideBarItem";
import { GetPositiveOrNegativeBalanceReport } from "../../services/services/Reports";
import { StatusCode } from "../../enums";
import Toast from "../common/Toast";
import { Dimmer, Loader } from "semantic-ui-react";
import { setModalSocialWorkWithholdings, setModalUnpaidBills, setModalBillsPerPeriod, setModalExportLiquidation } from "../../redux/modal";
import { useDispatch } from "react-redux";
import SocialWorkWithholdingsModal from "./SocialWorkWithholdingsModal";
import UnpaidBillsModal from "./UnpaidBillsModal";
import BillsPerPeriodModal from "./BillsPerPeriodModal";
import ExportLiquidationModal from "./ExportLiquidationModal";
import PictureInPictureRoundedIcon from '@material-ui/icons/PictureInPictureRounded';

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={5}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
));

const useStyles = makeStyles(() => ({
    sideBarIconStyles: {
        fontSize: "2em",
        marginRight: 5,
        transition: 'margin-left 0.3s ease !important',
    },
    sideBarTextItemStyles: {
        fontSize: "1em",
        color: "#363534",
        transition: 'opacity 0.3s ease !important',
    }
}));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

export default function ReportsHandler() {
    const dispatch = useDispatch();
    const [dataToast, setDataToast] = useState({
        active: false,
        message: '',
        type: '',
    });
    const classStyle = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingActive, setLoadingActive] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function HandleBalanceReport(isPositive) {
        try {
            setLoadingActive(true);
            const { data, status } = await GetPositiveOrNegativeBalanceReport(isPositive);
            if (status === StatusCode.Ok) {
                UtilityFNExport("pdf", `Saldos ${isPositive ? 'Positivos' : 'Negativos'}`, data.results);
            }
            setAnchorEl(null);
        } catch (err) {
            const errorMessage = err.message || 'No se pudo obtener el PDF.';
            MessageNotification(errorMessage, "error");
        } finally {
            setLoadingActive(false);
        }
    }

    function HandleSocialWorkWithholdings() {
        dispatch(setModalSocialWorkWithholdings(true));
        setAnchorEl(null);
    }

    function HandleUnpaidBills() {
        dispatch(setModalUnpaidBills(true));
        setAnchorEl(null);
    }
    
    function HandleBillsPerPeriod() {
        dispatch(setModalBillsPerPeriod(true));
        setAnchorEl(null);
    }
    function HandleExportSettlement() {
        dispatch(setModalExportLiquidation(true));
        setAnchorEl(null);
    }

    //#region UTILITY
    function UtilityFNExport(extension, nameOfFile, responseApi) {
        let fileName = `${nameOfFile}.${extension}`;
        let blob = new Blob([responseApi], {
            type: "application/pdf",
        });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    function MessageNotification(message, type) {
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error",
            type
        });
    }
    //#endregion
    return (
        <>
            <SideBarItem urlToRedirect="#" titleTooltip="Reportes" dispatchAction={handleClick}>
                <>
                    <DescriptionOutlinedIcon className={classStyle.sideBarIconStyles} />
                    <Typography className={classStyle.sideBarTextItemStyles}>Reportes</Typography>
                </>
            </SideBarItem>
            <StyledMenu
                id='customized-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div hidden={!loadingActive}>
                    <Dimmer className="loader-fixed" active inverted>
                        <Loader size="big">Cargando...</Loader>
                    </Dimmer>
                </div>
                {/* region Saldos */}
                <StyledMenuItem onClick={() => HandleBalanceReport(true)}>
                    <ListItemIcon>
                        <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText primary='Saldos Positivos' />
                </StyledMenuItem>

                <StyledMenuItem onClick={() => HandleBalanceReport(false)}>
                    <ListItemIcon>
                        <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText primary='Saldos Negativos' />
                </StyledMenuItem>
                {/* endRegion Saldos */}

                <StyledMenuItem onClick={() => HandleSocialWorkWithholdings()}>
                    <ListItemIcon>
                        <PictureInPictureRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Retenciones Obra Social Profesional' />
                </StyledMenuItem>

                <StyledMenuItem onClick={() => HandleUnpaidBills()}>
                    <ListItemIcon>
                        <PictureInPictureRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Facturas Impagas' />
                </StyledMenuItem>

                <StyledMenuItem onClick={() => HandleBillsPerPeriod()}>
                    <ListItemIcon>
                        <PictureInPictureRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Resumen Facturas Colegio' />
                </StyledMenuItem>

                <StyledMenuItem onClick={() => HandleExportSettlement()}>
                    <ListItemIcon>
                        <PictureInPictureRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Exportar Liquidación' />
                </StyledMenuItem>

                {dataToast.active && (
                    <Toast dataToast={dataToast} setDataToast={setDataToast} />
                )}
            </StyledMenu>
            <SocialWorkWithholdingsModal />
            <UnpaidBillsModal />
            <BillsPerPeriodModal />
            <ExportLiquidationModal />
        </>
    );
}
