export const _DEFAULT_CONFIG_PARAMS = {
    montoCuotaSocietaria: 0,
    montoMantenimientoMatricula: 0,
    porcentajeDebitoColegio: 0,
    porcentajeDebitoRegional: 0,
    motivoDebitoAutomatico: "Debito Automático",
    montoGastoNoSocio: 0,
    montoSistemaDocumental: 0,
    edad0a18: 0,
    edad19a35: 0,
    edad36a45: 0,
    edad46a55: 0,
    edad56a65: 0,
    edadMas65: 0,
    recAntecEnfermedadesCatastrof: 0,
    edadMaximaCoberturaHijoAdulto: 0,
    retencionGananciaMinima: 0,
    retencionMontoExcluido: 0
}

export const _DEFAULT_VALUES_BILL = {
    values: {
        obraSocialId: null, 
        planObraSocialId: null,
        fecha: null, 
        periodo: null,
        monto: 0,
        profesionales: []
    },
    filterObj: {
        fecha: null,
        obraSocialId: null,
        pagina: 1,
        cantidad: 20,
        estadoFacturaId: null,
        refacturadoId: null
    },
    modals: {
        create: {show: false, data: null},
        delete: {show: false, data: null},
        edit: {show: false, data: null},
        profesionalList: {show: false, data: null},
        view: {show: false, data: null}
    }
}

export const _DEFAULT_VALUES_ACCREDITATION = {
    values: {
        fecha: null,
        profesionalId: null,
        profesionalNombre: null,
        monto: null,
        descripcion: null
    },
    errorNotification: {
        profesionalId: false,
        detalle: false,
        importe: false,
        cantidadCuotas: false,
        fecha: false
    },
    filterObj: {profesional: {value: null, label: null}, pagina: null},
    modals: {
        create: {show: false, data: null},
        edit: {show: false, data: null},
        delete: {show: false, data: null},
        showComment: {show: false, data: null}
    }
}

export const _DEFAULT_VALUES_DESCOUNT = {
    values: {id: 0, fecha: null, monto: null, descripcion: null, profesionalId: null, profesionalName: null},
    errorNotification: {fecha: false, profesionalId: false, monto: false, descripcion: false},
    filterObj: {profesional: {value: null, label: null}, fechaDesde: null, fechaHasta: null},
    modals: {
        create: {show: false, data: null},
        edit: {show: false, data: null},
        delete: {show: false, data: null}
    }
}

export const _DEFAULT_VALUES_INSTALLMENT = {
    values: {
        importe: null,
        detalle: null,
        cantidadCuotas: null,
        fecha: null,
        profesionalId: null,
        profesionalNombre: null,
        cuotas: []
    },
    errorNotification: {
        profesionalId: false,
        detalle: false,
        importe: false,
        cantidadCuotas: false,
        fecha: false
    },
    filterObj: {profesional: {value: null, label: null}},
    modals: {
        create: {show: false, data: null},
        edit: {show: false, data: null},
        delete: {show: false, data: null},
        showComment: {show: false, data: null},
        debitManually: {show: false, data: null}
    }
}

export const _DEFAULT_VALUES_PROFIT = {
    id: 0,
    masDePesos: 0,
    aPesos: 0,
    pesos: 0,
    sobreExcluido: 0,
    masElPorcentaje: 0
}

export const _DEFAULT_ERROR_NOTIFICATION = {
    masDePesos: false,
    aPesos: false,
    pesos: false,
    sobreExcluido: false,
    masElPorcentaje: false
}

export const _DEFAULT_VALUES_PROFIT_MODAL = {
    new: {show: false},
    edit: {show: false}
}

export const _DEFAULT_VALUES_RECEIPTS = {
    _LOADER_FOR_SELECT: {
        bills: false,
        profesionals: false,
        socialWorks: false
    },
    _VALUES_RECEIPT:{
        _PROFESSIONAL: {
            sucursal: null,
            numero: null,
            fecha: new Date(),
            monto: null,
            interes: null,
            enConceptoDe: null,
            deposito: false,
            afectaSaldo: false,
            profesionalId: null
        },
        _SOCIALWORK: {
            sucursal: null,
            numero: null,
            fecha: new Date(),
            monto: null,
            interes: null,
            enConceptoDe: null,
            deposito: false,
            facturaId: null,
            obraSocialId: null,
            factura: {
                value: null,
                label: null
            },
            obraSocial: {
                value: null,
                label: null
            }
        },
        _FREE: {
            sucursal: null,
            numero: null,
            fecha: new Date(),
            monto: null,
            interes: null,
            enConceptoDe: null,
            deposito: false,
            nombre: null,
        },
    },
    _TABS_PANELS: {
        _PROFESSIONAL: 0,
        _SOCIALWORK: 1,
        _FREE: 2
    },
    _MODALS: {
        new: {show: false, data: null},
        view: {show: false, data: null},
        reactivate: {show: false, data: null},
        delete: {show: false, data: null}
    },
    _DATA_FOR_SELECT: {
        socialWorkList: [],
        billList: [],
        profesionalList: []
    },
    _FILTER_OBJ: {
        socialWork: null,
        dateFrom: null,
        dateTo: null,
        numberReceipt: null,
        deleted: false,
        entityId: null,
    },
    _ERROROBJ: {
        numero: false,
        sucursal: false,
        fecha: false,
        profesionalId: false,
        facturaId: false,
        obraSocialId: false,
        monto: false,
        interes: false,
        enConceptoDe: false,
        nombre: false,
    }
}

export const _DEFAULT_VALUES_BANK = {
    id: 0,
    nombre: ""
}

export const _DEFAULT_VALUES_BANK_FILTER_PARAM = {
    bank: null,
    dadosDeBaja: false
}

export const _DEFAULT_VALUES_BANK_MODAL = {
    new: false,
    edit: false,
    reactivate: false,
    delete: false
}

export const _DEFAULT_LIQUIDATION_VALUES = {
    _ADITIONAL_DATA: {
        accreditationList: [],
        descountList: [],
        installmentList: [],
        accreditationBalanceList: []
    },
    _MODALS_NOTIFICATION: {
        notificationRestoreLiquidation: {
            show: false,
            data: null
        }
    },
    _LIST_NODES_TO_EXPAND: {
        _DESCOUNT: `D_1`,
        _ACCREDITATION: `A_2`,
        _ACCREDITATION_BALANCE: `AB_3`,
        _INSTALLMENT: `I_4`
    }
}
export const _DEFAULT_VALUES_MANUALDEBIT = {
    _MODALS: {
        edit: {show: false, data: null}
    },
    _DATA_OF_BILL: {
        id: null,
        fecha: null,
        total: null,
        usuarioFactura: [],
        obraSocial: {id: null, nombre: null},
        plan: {id: null, nombre: null}
    }
}
export const _DEFAULT_DESCOUNT_LIQUIDATION_VALUES = {
    _DESCOUNTID: 1,
    _ACCREDITATIONID: 2,
    _INSTALLMENTID: 3,
    _ACCREDITATION_BALANCE: 4,
    _NODES_CHECKBOXTREE: [],
  }
export const _DEFAULT_BILL_LIQUIDATION_VALUES = {
    _MODALS: {
        notificationManualDebit: {
            show: false,
            data: null
        }
    },
    _FILTER_OBJ: {
        pendingBills: true
    }
}
